// eslint-disable-next-line import/no-extraneous-dependencies
import { BasePlacement, VariationPlacement } from '@popperjs/core';

export const CLIENTS_PROJECT_GRID_ROW_HEIGHT = 24;
export const GRID_HEADER_ROW_FONT_WEIGHT = 600;
export const DEFAULT_GRID_PAGINATION_HEIGHT = 49;
export const DEFAULT_GRID_SKELETON_ROWS_AMOUNT = 50;
export const DEFAULT_GRID_FIT_SCREEN_ROWS_AMOUNT = 22;

export const GRID_TIME_CELL_ROW_HEIGHT = 72;
export const GRID_ROW_WITH_ADD_BUTTON_HEIGHT = 50;

export const EDIT_COLUMN_HEADER_CLASS = 'edit-column-header';
export const EDIT_COLUMN_CELL_CLASS = 'edit-column-cell';
export const RELATIVE_EDIT_COLUMN_CELL_CLASS = 'relative-edit-column-cell';

export const ALTERNATING_ROW_OPACITY = '66';
export const EDIT_BUTTON_DARK_THEME_BACKGROUND_COLOR = '#FEF2E61A';

export const CELL_FONT_SIZE = 14;
export const COLUMN_HEADER_FONT_SIZE = 12;
export const SMALL_COLOR_CIRCLE_SIZE = 10;
export const CLIENT_PROJECTS_CELL_FONT_SIZE = 12;

export const GRID_ICON_SIZE = 18;
export const GRID_CHEVRON_ICON_SIZE = 10;
export const DEPARTMENT_GRID_ICON_SIZE = 16;

export const GRID_ROW_HEIGHT = 26;

export const EDIT_COLUMN_WIDTH = 34;

export const COMMON_GRID_PROPS = {
	headerRowHeight: GRID_ROW_HEIGHT,
	rowHeight: GRID_ROW_HEIGHT,
};

export const DEFAULT_ID_COLUMN_WIDTH = 66;
export const DEFAULT_ID_COLUMN_CONFIG = {
	width: DEFAULT_ID_COLUMN_WIDTH,
	fixed: true,
};
export const TRACKED_FACT_COLUMN_WIDTH = 127;
export const TRACKED_BILLABLE_COLUMN_WIDTH = 145;
export const DEFAULT_ID_COLUMN_WITH_COLOR_WIDTH = 80;
export const MODAL_WIDTH = 244;

export const TOOLTIP_FALLBACK_PLACEMENTS: Array<
	BasePlacement | VariationPlacement
> = [
	'top',
	'right',
	'left',
	'bottom',
	'top-start',
	'top-end',
	'bottom-start',
	'bottom-end',
	'left-start',
	'left-end',
	'right-start',
	'right-end',
];

export const IGNORE_SELECTION_CLASS_NAME = 'ignore-selection';

export const EDIT_CELL_RIGHT_PADDING = 10;
