import { Theme } from '@emotion/react';
import { ThemeMode } from '@mms/mms-ui-library';

import { ALTERNATING_ROW_OPACITY } from '@/components/Grid/constants';

const ODD_DARK_BACKGROUND_COLOR = '#373B49';
const HOVER_DARK_BACKGROUND_COLOR = '#303340';

export const getOddRowBackgroundColor = (theme: Theme) =>
	theme.mode === ThemeMode.DARK
		? ODD_DARK_BACKGROUND_COLOR
		: theme.palette['primary-2'];

export const getEvenRowBackgroundColor = (theme: Theme) =>
	theme.palette['primary-1'];

export const getAlternateColumnBackgroundColor = (theme: Theme) =>
	theme.mode === ThemeMode.DARK
		? `${theme.palette['primary-9']}${ALTERNATING_ROW_OPACITY}`
		: theme.palette['primary-2'];

export const getTaskInfoColumnBackgroundColor = (theme: Theme) =>
	theme.mode === ThemeMode.DARK
		? theme.palette['primary-3']
		: theme.palette['primary-2'];

export const getTotalColumnBackgroundColor = (theme: Theme) =>
	theme.mode === ThemeMode.DARK
		? theme.palette['primary-2']
		: theme.palette['primary-3'];

export const getHoverBackgroundColor = (theme: Theme) =>
	theme.mode === ThemeMode.DARK
		? HOVER_DARK_BACKGROUND_COLOR
		: theme.palette['primary-3'];

export const getTotalColumnHoverBackgroundColor = (theme: Theme) =>
	theme.palette['primary-4'];

export const getHoverBorderColor = (theme: Theme) => theme.palette['primary-4'];
