import moment from 'moment';

import { generateYearsCompanyExistence } from '@/helpers/date';

export const date = new Date();
export const currentDate = moment(date);

export const COMPANY_FOUNDATION_DATE = 2020;

export const MONTH_IN_YEAR = 12;

export const TIME_PERIOD = {
	Year: 'Year',
	Month: 'Month',
	Quarter: 'Quarter',
};

export const quarters = [
	'January-March',
	'April-June',
	'July-September',
	'October-December',
];

export const quartersShorthands: Record<string, string> = {
	'Jan-Mar': 'January-March',
	'Apr-Jun': 'April-June',
	'Jul-Sep': 'July-September',
	'Oct-Dec': 'October-December',
};

export const weekDays = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thirsday',
	'Friday',
	'Saturday',
];

export const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const dateNavigatorSelectors = [
	{
		title: TIME_PERIOD.Year,
		items: generateYearsCompanyExistence(),
	},
	{
		title: TIME_PERIOD.Month,
		items: months,
	},
	{
		title: TIME_PERIOD.Quarter,
		items: quarters,
	},
];

export const MIN_SELECT_YEAR = 1900;
export const MAX_SELECT_YEAR = 2100;

export const MIN_DATE_MONTH = 0;
export const MIN_DATE_DAY = 1;
export const MIN_DATE_YEAR = 2000;
export const MAX_DATE_MONTH = 11;
export const MAX_DATE_DAY = 31;
export const MAX_DATE_YEAR = 2120;

export const YYYY_MM_DD_MOMENT_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const FULL_DATE_FORMAT = 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ';

export enum DateFormat {
	DD_MM_YYYY = DATE_FORMAT,
	YYYY_MM_DD = YYYY_MM_DD_MOMENT_DATE_FORMAT,
}

export const timeUnits = {
	MILLISECONDS_IN_SECOND: 1000,
	SECONDS_IN_MINUTE: 60,
	MINUTES_IN_HOUR: 60,
	HOURS_IN_DAY: 24,
	MILLISECONDS_IN_MINUTE: 1000 * 60,
	MILLISECONDS_IN_HOUR: 1000 * 60 * 60,
	MILLISECONDS_IN_DAY: 1000 * 60 * 60 * 24,
};
